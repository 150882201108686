$ = window.jQuery;
// import './cookies';
import '../scss/main.scss';
// import PhotoSwipe from 'photoswipe/dist/photoswipe';
// import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

// import '../../../../../../webroot/themes/all/js/map';

// import { Foundation } from 'foundation-sites/js/foundation.core.js';

// import { Magellan } from 'foundation-sites/js/foundation.magellan.js';
// Foundation.plugin(Magellan, 'Magellan');

// import { Reveal } from 'foundation-sites/js/foundation.reveal.js';
// Foundation.plugin(Reveal, 'Reveal');

// import { SmoothScroll } from 'foundation-sites/js/foundation.smoothScroll.js';
// Foundation.plugin(SmoothScroll, 'SmoothScroll');

// import { Triggers } from 'foundation-sites/js/foundation.util.triggers';
// Triggers.init($, Foundation);

Foundation.addToJquery($);

import './map';
import './mobile-nav';
import './more-nav';
import './section-header';
// import './header';
import './gallery-carousel';
import './parcels-carousel';
import './availability-carousel';
import './virtualtour';
import './banner-main-portfolio';

import AOS from 'aos';
AOS.init({
	offset: -50,
	delay: 100,
	duration: 900,
	anchorPlacement: 'top-bottom',
	easing: 'ease-in-out-back'
});

$(function () {
  $(document).foundation();
  $('body').removeClass('is-loading');

});

// Timeout for success alert box from form submissions
setTimeout(function() {
	var alertBox = document.querySelector('.alert-box.success');
	if (alertBox) {
	alertBox.style.transition = 'opacity 0.5s ease';
	alertBox.style.opacity = '0';
	setTimeout(function() {
		alertBox.style.display = 'none';
	}, 500); // Match the duration of the fade-out transition
	}
}, 1500);

// import './exit-intent';
